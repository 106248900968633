import Layout from 'components/Layout';
import { graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import NotFoundTpl from 'templates/NotFoundTpl';

const Tpl = props => (
  <Layout location={props.location}>
    <NotFoundTpl {...props} />
  </Layout>
);
const Index = props => (
  <StaticQuery
    query={graphql`
      query NotfoundQuery {
        notfoundJson {
          title
          meta {
            description
          }
          notfound {
            heading
            subheading
            btnText
          }
        }
      }
    `}
    render={data => <Tpl data={data} {...props} />}
  />
);
Tpl.propTypes = {
  location: PropTypes.object,
};

export default Index;
