import Metadata from 'components/Metadata';
import Wrapper from 'components/Wrapper';
import PropTypes from 'prop-types';
import React from 'react';
import * as styles from './NotFound.module.scss';

const NotFoundTpl = ({ data }) => {
  const {
    notfoundJson: {
      title,
      meta,
      notfound: { heading, subheading },
    },
  } = data;
  return (
    <section>
      <Metadata title={title} description={meta.description} />
      <Wrapper marginBottom="S">
        <div className={styles.root}>
          <div>
            <h1 className={styles.heading}>{heading}</h1>
            <div className={styles.subheading}>
              {subheading}
            </div>
          </div>
        </div>
      </Wrapper>
    </section>
  );
};
NotFoundTpl.propTypes = {
  data: PropTypes.object,
};

export default NotFoundTpl;
